import { Athlete } from '../users/athlete.model';

export interface Guest {
  id: number | null;
  athlete: Athlete;
  status: GuestStatus;
  inviteDate: Date;
  anonymousTester: boolean;
}

export enum GuestStatus {
  NONE = 'NONE',
  AUTOMATICALLY_ACCEPTED = 'AUTOMATICALLY_ACCEPTED',
  PRODUCT_AFFECTATION_ERROR = 'PRODUCT_AFFECTATION_ERROR',
  PRODUCT_AFFECTED = 'PRODUCT_AFFECTED',
  VALIDATION_PRODUCT_PENDING = 'VALIDATION_PRODUCT_PENDING',
  SURVEYS_TO_FILL = 'SURVEYS_TO_FILL',
  SURVEYS_COMPLETED = 'SURVEYS_COMPLETED',
}
