import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of, switchMap } from 'rxjs';
import { environment } from 'src/environments/environment';
import { EventGuestInformation } from '../../models/events/event-guest-information.model';
import { AuthService } from '../auth/auth.service';
import { AuthorityVerificationService } from '../authority-verification/authority-verification.service';
import { GuestInformationInterface } from './guest-information.interface';

@Injectable({
  providedIn: 'root',
})
export class GuestInformationService implements GuestInformationInterface {
  constructor(
    private httpClient: HttpClient,
    private authorityVerification: AuthorityVerificationService,
  ) {}

  getGuestInformation(eventId: number): Observable<EventGuestInformation> {
    const user = AuthService.currentUser;
    if (user) {
      if (this.authorityVerification.hasS2ALocalRole(user) || this.authorityVerification.hasTestingManagerRole(user)) {
        return this.httpClient
          .get<EventGuestInformation>(environment.apiUrl + `/events/guest-information/${eventId}`)
          .pipe(switchMap(async (eventGuestInformation) => eventGuestInformation));
      } else {
        const eventGuestInformation: EventGuestInformation = { eventId, guestInformations: [] };
        return of(eventGuestInformation);
      }
    } else throw new Error('undefined user');
  }
}
