import { LangField, LangValue } from '../models/lang-field.model';
import { Language } from '../models/language.model';
import { LanguageService } from '../services/language/language.service';

export class LangFieldUtils {
  static getValueByLang(lang: string, langField: LangField, defaultIfEmpty: boolean = true): string {
    if (langField == null || langField.langValues == null) {
      return '';
    } else {
      const targetIndex = langField.langValues.findIndex((item) => item.lang == lang);
      if (targetIndex != -1) {
        return langField.langValues[targetIndex].value;
      } else if (defaultIfEmpty && lang != LanguageService.defaultlang) {
        return this.getValueByDefaultLang(langField);
      } else {
        return '';
      }
    }
  }

  static getValueByDefaultLang(langField: LangField): string {
    return this.getValueByLang(LanguageService.defaultlang, langField);
  }

  static getValueByCurrentLang(langValues: LangValue[]): string {
    if (langValues == null) {
      return '';
    } else {
      const targetIndex = langValues.findIndex((item) => item.lang == LanguageService.currentLang);
      if (targetIndex == -1) {
        return '';
      } else {
        return langValues[targetIndex].value;
      }
    }
  }

  static verifyLangField(langValues: LangValue[], languageError: string, lengthError: string): string {
    const containsEmptyOrNull = langValues
      .filter((langValue) => langValue.lang == LanguageService.defaultlang)
      .some((lV) => lV.value == '' || lV.value == null);
    const containsLegitData = !langValues.some(
      (lV) => lV.value != null && lV.value != '' && (lV.value.length < 5 || lV.value.length > 255),
    );
    const containsEnglishLanguage = langValues.some((langValue) => langValue.lang == LanguageService.defaultlang);

    if (containsEmptyOrNull) {
      return languageError;
    } else {
      if (containsEnglishLanguage) {
        if (containsLegitData) {
          return '';
        } else {
          return lengthError;
        }
      } else {
        return languageError;
      }
    }
  }

  // retourne true si les autres langues que la langue par défaut sont bien remplis
  static verifyOptionalLangField(languages: Language[], langValues: LangValue[]): boolean {
    const countLangValuesDefaultLang = langValues.filter(
      (langValue) => langValue.lang == LanguageService.defaultlang && langValue.value != null && langValue.value != '',
    ).length;
    const countLangValuesBlankDefaultLang = langValues.filter(
      (langValue) =>
        langValue.lang == LanguageService.defaultlang && (langValue.value == null || langValue.value == ''),
    ).length;

    return languages
      .filter((language) => language.key != LanguageService.defaultlang)
      .every((language) => {
        const langValuesFromLanguage = langValues.filter((langValue) => langValue.lang == language.key);

        return (
          langValuesFromLanguage != null &&
          langValuesFromLanguage.length >= countLangValuesDefaultLang &&
          countLangValuesBlankDefaultLang ==
            langValuesFromLanguage.filter((langValue) => langValue.value == '' || langValue.value == null).length
        );
      });
  }

  static createOrEditLangField(langField: LangField, langValue: LangValue): LangField {
    if (langField.langValues == null) {
      langField.langValues = [langValue];
      return langField;
    } else {
      const targetIndex = langField.langValues.findIndex((item) => item.lang == langValue.lang);
      if (targetIndex == -1) {
        langField.langValues.push(langValue);
        return langField;
      } else {
        langField.langValues[targetIndex].value = langValue.value;
        return langField;
      }
    }
  }
}
