import { Injectable } from '@angular/core';
import { CommentService } from '../comment.service';
import { FeedbackComment } from '../../../models/feedback/feedback-comment.model';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class SurveyCommentService extends CommentService {
  constructor(httpClient: HttpClient) {
    super(httpClient);
  }

  addComment(ids: string[], feedbackComment: FeedbackComment): Observable<FeedbackComment> {
    return this.httpClient.post<FeedbackComment>(environment.apiUrl + `/comments/survey/${ids[0]}`, feedbackComment);
  }

  updateComment(ids: string[], feedbackComment: FeedbackComment): Observable<FeedbackComment> {
    return this.httpClient.put<FeedbackComment>(environment.apiUrl + '/comments/survey', feedbackComment);
  }

  deleteComment(ids: string[], commentId: number): Observable<void> {
    return this.httpClient.delete<void>(environment.apiUrl + `/comments/survey/${commentId}`);
  }
}
