import { Injectable } from '@angular/core';
import Dexie, { Table } from 'dexie';
import { firstValueFrom } from 'rxjs';
import { Category } from 'src/app/models/events/category.model';
import { EventComplementaryInfoResponses } from 'src/app/models/events/event-complementary-info-response.model';
import { EventGuestInformation } from 'src/app/models/events/event-guest-information.model';
import { Event } from 'src/app/models/events/event.model';
import { GuestInformation } from 'src/app/models/events/guest-information.model';
import { GuestStatus } from 'src/app/models/events/guest.model';
import { EventUserRanking } from 'src/app/models/events/ranking/event-user-ranking.model';
import { FeedbackComment } from 'src/app/models/feedback/feedback-comment.model';
import { CriteriaSet } from 'src/app/models/surveys/criteria-set.model';
import { CriteriaSetCriteria } from 'src/app/models/surveys/criteriaset-criteria.model';
import { SurveyCriteriaSet } from 'src/app/models/surveys/survey-criteriaset.model';
import { Survey } from 'src/app/models/surveys/survey.model';
import { AuthService } from '../auth/auth.service';
import { CategoryService } from '../category/category.service';
import { CommentService } from '../comment/comment.service';
import { ComplementaryInfoResponseService } from '../complementary-info-reponse/complementary-info-response.service';
import { GuestInformationService } from '../guest-information/guest-information.service';
import { RankingService } from '../ranking/ranking.service';
import { StorageService } from '../storage/storage.service';
import { EventSurveys, SurveyLoadService } from '../survey/survey-load.service';
import { LOCAL_CHANGE } from 'src/app/utils/general.utils';

@Injectable({
  providedIn: 'root',
})
export class DatabaseService extends Dexie {
  events: Table<Event, number>;
  eventSurveys: Table<EventSurveys, number>;
  categories: Table<Category, number>;
  eventUserRankings: Table<EventUserRanking, number>;
  eventGuestInformations: Table<EventGuestInformation, number>;
  eventComplementaryInfoResponses: Table<EventComplementaryInfoResponses, number>;

  populateInProgress: boolean;

  constructor(
    private storageService: StorageService,
    private surveyLoadService: SurveyLoadService,
    private categoryService: CategoryService,
    private rankingService: RankingService,
    private guestInformationService: GuestInformationService,
    private complementaryInfoResponseService: ComplementaryInfoResponseService,
    private commentService: CommentService,
  ) {
    super('s2a-testing-dexie', { allowEmptyDB: true });
    this.version(3).stores({
      events: 'id',
      eventSurveys: '++id, eventId',
      categories: 'id',
      eventUserRankings: '++id, eventId',
      eventGuestInformations: '++id, eventId',
      eventComplementaryInfoResponses: '++id, eventId',
    });
  }

  async updateSurvey(survey: Survey): Promise<Survey> {
    this.storageService.set(LOCAL_CHANGE, true);
    survey.localChange = true;

    const eventSurveys = await this.eventSurveys.toArray();
    const eventSurvey = eventSurveys.find((ev) => ev.surveys.find((s) => s.id === survey.id) !== undefined);
    if (eventSurvey) {
      eventSurvey.surveys = eventSurvey.surveys.map((s) => (s.id === survey.id ? survey : s));
      for (const group of eventSurvey.surveysGrouping) {
        const key = group[0];
        let surveys = group[1];
        surveys = surveys.map((s) => (s.id === survey.id ? survey : s));
        eventSurvey.surveysGrouping.set(key, surveys);
      }
      await this.eventSurveys.put(eventSurvey, eventSurvey.id!);
      const s = eventSurvey.surveys.find((s) => (s.id === survey.id ? survey : s));
      if (s) return s;
    }
    return survey;
  }

  async getSurveyById(id: number, showDeletedComments: boolean = false): Promise<Survey | undefined> {
    const eventSurveys = await this.eventSurveys.toArray();
    for (const group of eventSurveys) {
      let survey = group.surveys.find((s) => s.id === id);
      if (survey) {
        survey = Object.assign(new Survey(), survey);
        survey.event = Object.assign(new Event(), survey.event);
        survey.surveyCriteriaSets = survey.surveyCriteriaSets.map((scs) => {
          const surveyCriteriaSet = Object.assign(new SurveyCriteriaSet(), scs);
          surveyCriteriaSet.criteriaSet = Object.assign(new CriteriaSet(), surveyCriteriaSet.criteriaSet);
          surveyCriteriaSet.criteriaSet.criterias = surveyCriteriaSet.criteriaSet.criterias.map((criteria) =>
            Object.assign(new CriteriaSetCriteria(), criteria),
          );
          return surveyCriteriaSet;
        });
        return survey;
      }
    }
    return;
  }

  async getEvents(): Promise<Event[]> {
    const events = (await this.events.toArray()).map((event) => Object.assign(new Event(), event));
    events.forEach((event) => (event.guestInfo = Object.assign(new GuestInformation(), event.guestInfo)));
    events.sort((a, b) => new Date(b.beginDate!).getTime() - new Date(a.beginDate!).getTime());
    return events;
  }

  async getEventSurveysByEventId(eventId: number): Promise<EventSurveys | undefined> {
    try {
      const surveys = await this.eventSurveys.where('eventId').equals(eventId).first();
      if (surveys) {
        surveys.surveys = surveys.surveys.map((survey) => {
          const s = Object.assign(new Survey(), survey);
          s.product = survey.product;
          return s;
        });
        surveys.surveysGrouping.forEach((surveys) => {
          surveys.forEach((survey) => {
            survey.surveyCriteriaSets = survey.surveyCriteriaSets.map((scs) => {
              const surveyCriteriaSet = Object.assign(new SurveyCriteriaSet(), scs);
              surveyCriteriaSet.criteriaSet = Object.assign(new CriteriaSet(), surveyCriteriaSet.criteriaSet);
              surveyCriteriaSet.criteriaSet.criterias = surveyCriteriaSet.criteriaSet.criterias.map((criteria) =>
                Object.assign(new CriteriaSetCriteria(), criteria),
              );
              return surveyCriteriaSet;
            });
          });
        });
        surveys.surveys.forEach((survey) => {
          survey.surveyCriteriaSets = survey.surveyCriteriaSets.map((scs) => {
            const surveyCriteriaSet = Object.assign(new SurveyCriteriaSet(), scs);
            surveyCriteriaSet.criteriaSet = Object.assign(new CriteriaSet(), surveyCriteriaSet.criteriaSet);
            surveyCriteriaSet.criteriaSet.criterias = surveyCriteriaSet.criteriaSet.criterias.map((criteria) =>
              Object.assign(new CriteriaSetCriteria(), criteria),
            );
            return surveyCriteriaSet;
          });
        });
      }
      return surveys;
    } catch (err) {
      console.error(err);
      return undefined;
    }
  }

  async getAllAnonTesters(): Promise<GuestInformation[]> {
    const eventGuestInformations = await this.eventGuestInformations.toArray();
    const anonTesters: GuestInformation[] = [];
    for (const guestInformations of eventGuestInformations) {
      anonTesters.push(
        ...guestInformations.guestInformations.filter((guest) => guest.anonymousTester && guest.localChange),
      );
    }
    return anonTesters;
  }

  async populate(events: Event[]) {
    this.populateInProgress = true;

    const user = AuthService.currentUser;
    if (!user) return;

    // Data to fill
    const eventSurveys: EventSurveys[] = [];
    const categories: Category[] = [];
    const eventUserRankings: EventUserRanking[] = [];
    const eventGuestInformations: EventGuestInformation[] = [];
    const eventComplementaryInfoResponses: EventComplementaryInfoResponses[] = [];

    const filteredEvents = events.filter((event) => {
      const currentUserHasSurveys = event.guestInfo.guestStatus === GuestStatus.SURVEYS_TO_FILL;
      const anonTestersHaveSurveys = event.guests.some(
        (guest) => guest.anonymousTester && guest.status === GuestStatus.SURVEYS_TO_FILL,
      );
      return currentUserHasSurveys || anonTestersHaveSurveys;
    });

    // Fetch all events dependencies
    for (const event of filteredEvents) {
      try {
        const surveys = await this.surveyLoadService.initSurveysFromEvent(event);
        for (const survey of surveys.surveys) {
          await this.fetchSurveyComments(survey);
        }
        eventSurveys.push(surveys);
      } catch (err) {
        console.error(err);
      }

      try {
        const guestInformation = await firstValueFrom(this.guestInformationService.getGuestInformation(event.id!));
        for (const guestInfo of guestInformation.guestInformations) {
          if (guestInfo.anonymousTester) {
            const guestSurveys: Survey[] = [];
            for (const surveyInfo of guestInfo.surveyInformations) {
              const survey = await this.surveyLoadService.getSurveyByIdWithAllRelationships(surveyInfo.surveyId);
              survey.event = event;
              guestSurveys.push(survey);
            }
            eventSurveys.push({ surveys: guestSurveys, surveysGrouping: new Map(), eventId: event.id! });
          }
        }
        eventGuestInformations.push(guestInformation);
      } catch (err) {
        console.error(err);
      }

      const categoryNotLoaded = categories.every((c) => c.id !== event.categoryId);
      if (categoryNotLoaded) {
        try {
          const category = await firstValueFrom(this.categoryService.getCategory(event.categoryId));
          categories.push(category);
        } catch (err) {
          console.error(err);
        }
      }

      try {
        const eventUserRanking = await firstValueFrom(this.rankingService.getUserRankings(user.id!, event.id!));
        eventUserRankings.push(eventUserRanking);
      } catch (err) {
        console.error(err);
      }

      try {
        const complementaryInfoResponse = await firstValueFrom(
          this.complementaryInfoResponseService.getComplementaryInfoResponses(event.id!),
        );
        eventComplementaryInfoResponses.push(complementaryInfoResponse);
      } catch (err) {
        console.error(err);
      }
    }

    // Clear and store all local database data
    try {
      await this.events.clear();
      await this.events.bulkAdd(filteredEvents);
    } catch (err) {
      console.error(`Error db clearing events: ${err}`);
    }
    try {
      await this.eventSurveys.clear();
      await this.eventSurveys.bulkAdd(eventSurveys);
    } catch (err) {
      console.error(`Error db clearing eventSurveys: ${err}`);
    }
    try {
      await this.categories.clear();
      await this.categories.bulkAdd(categories);
    } catch (err) {
      console.error(`Error db clearing categories: ${err}`);
    }
    try {
      await this.eventUserRankings.clear();
      await this.eventUserRankings.bulkAdd(eventUserRankings);
    } catch (err) {
      console.error(`Error db clearing eventUserRankings: ${err}`);
    }
    try {
      await this.eventGuestInformations.clear();
      await this.eventGuestInformations.bulkAdd(eventGuestInformations);
    } catch (err) {
      console.error(`Error db clearing eventGuestInformations: ${err}`);
    }
    try {
      await this.eventComplementaryInfoResponses.clear();
      await this.eventComplementaryInfoResponses.bulkAdd(eventComplementaryInfoResponses);
    } catch (err) {
      console.error('Error db clearing eventComplementaryInfos => ', err);
    }

    this.populateInProgress = false;
  }

  private async fetchSurveyComments(survey: Survey): Promise<void> {
    // Fetch survey comments
    const surveyCommentsPromise: Array<Promise<FeedbackComment>> = [];
    for (const comment of survey.comments) {
      surveyCommentsPromise.push(firstValueFrom(this.commentService.getComment(comment.id)));
    }
    const surveyComments = await Promise.all(surveyCommentsPromise);
    survey.comments = surveyComments;

    // Fetch survey criteria sets comments
    for (const surveyCriteriaSet of survey.surveyCriteriaSets) {
      const surveyCriteriaSetCommentsPromise: Array<Promise<FeedbackComment>> = [];
      for (const comment of surveyCriteriaSet.comments) {
        surveyCriteriaSetCommentsPromise.push(firstValueFrom(this.commentService.getComment(comment.id)));
      }
      const surveyCriteriaSetComments = await Promise.all(surveyCriteriaSetCommentsPromise);
      surveyCriteriaSet.comments = surveyCriteriaSetComments;
    }
  }
}
