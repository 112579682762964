import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Preferences } from '@capacitor/preferences';
import { PushNotifications } from '@capacitor/push-notifications';
import { SplashScreen } from '@capacitor/splash-screen';
import { NavController, Platform } from '@ionic/angular';
import { AlertController } from '@ionic/angular/standalone';
import { TranslateService } from '@ngx-translate/core';
import { firstValueFrom } from 'rxjs';
import { User } from 'src/app/models/users/user.model';
import { isMobileOffline } from 'src/app/utils/offline.utils';
import { environment } from 'src/environments/environment';
import { OfflineService } from '../offline/offline.service';
import { PrincipalService } from '../principal/principal.service';
import { StorageService } from '../storage/storage.service';
import { UserService } from '../user/user.service';
import { isJwtExpired } from '../../utils/general.utils';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  static currentUser: User;

  constructor(
    private http: HttpClient,
    private navCtrl: NavController,
    private principalService: PrincipalService,
    private platform: Platform,
    private alertCtrl: AlertController,
    private translateService: TranslateService,
    private storageService: StorageService,
    private offlineService: OfflineService,
    private userService: UserService,
  ) {}

  private _currentUser: User | undefined = undefined;

  get currentUser(): User | undefined {
    return this._currentUser;
  }

  set currentUser(user: User) {
    this._currentUser = Object.assign(new User(), user);
    AuthService.currentUser = this._currentUser;
  }

  async login(credentials: LoginCredentials): Promise<boolean> {
    try {
      const jwt = await firstValueFrom(this.http.post<Jwt>(environment.apiUrl + '/authenticate', credentials));
      const token = jwt.id_token;
      if (token) {
        if (this.platform.is('capacitor')) {
          await Preferences.set({
            key: 'authenticationToken',
            value: token,
          });
        } else {
          if (credentials.rememberMe) {
            sessionStorage.removeItem('authenticationToken');
            localStorage.setItem('authenticationToken', token);
          } else {
            localStorage.removeItem('authenticationToken');
            sessionStorage.setItem('authenticationToken', token);
          }
        }
        await this.loadUser();
        return true;
      }
      return false;
    } catch (err) {
      console.error(err);
      return false;
    }
  }

  async logout(prompt = true): Promise<void> {
    if (!prompt) {
      await this.logoutLogic();
      await this.navCtrl.navigateRoot('/login');
      return;
    }

    const value = await firstValueFrom(this.translateService.get(['EXIT_CONFIRMATION', 'CANCEL', 'CONFIRM']));
    const alert = await this.alertCtrl.create({
      message: value.EXIT_CONFIRMATION,
      animated: true,
      backdropDismiss: true,
      buttons: [
        { role: 'cancel', text: value.CANCEL },
        { role: 'confirm', text: value.CONFIRM },
      ],
    });
    await alert.present();
    const { role } = await alert.onDidDismiss();
    if (role === 'confirm') {
      await SplashScreen.show({ autoHide: false });
      await this.logoutLogic();
      await SplashScreen.hide();
      await this.navCtrl.navigateRoot('/login');
    }
  }

  async loadUser(): Promise<void> {
    let token: string | null;
    if (this.platform.is('capacitor')) {
      const { value } = await Preferences.get({
        key: 'authenticationToken',
      });
      token = value;
    } else {
      token = localStorage.getItem('authenticationToken') || sessionStorage.getItem('authenticationToken');
    }
    if (token) {
      if (isJwtExpired(token)) {
        await this.logout(false);
        return;
      }

      if (isMobileOffline(this.platform)) {
        const user = await this.storageService.get<User>('currentUser');
        if (user) {
          this.currentUser = user;
        } else {
          const values = await firstValueFrom(
            this.translateService.get(['USER_NOT_FOUND_OFFLINE_HEADER', 'USER_NOT_FOUND_OFFLINE']),
          );
          const alert = await this.alertCtrl.create({
            header: values.USER_NOT_FOUND_OFFLINE_HEADER,
            message: values.USER_NOT_FOUND_OFFLINE,
            buttons: [
              {
                text: 'OK',
                role: 'confirm',
              },
            ],
          });
          await alert.present();
          const { role } = await alert.onDidDismiss();
          if (role === 'confirm') {
            await this.offlineService.setMode(false);
            window.location.reload();
          }
        }
      } else {
        try {
          const user = await this.principalService.identity();
          await this.storageService.set<User>('currentUser', user);
          this.currentUser = user;
        } catch (err) {
          console.error('error loading user => ', err);
        }
      }
    } else this._currentUser = undefined;
  }

  private async logoutLogic(): Promise<void> {
    this._currentUser = undefined;
    if (this.platform.is('capacitor')) {
      await PushNotifications.unregister();
      await PushNotifications.removeAllListeners();
      try {
        await firstValueFrom(this.userService.setTokenNotification(null));
      } catch (err) {
        console.error(err);
      }
      await Preferences.remove({
        key: 'authenticationToken',
      });
    } else {
      localStorage.removeItem('authenticationToken');
      sessionStorage.removeItem('authenticationToken');
    }
  }
}

export type LoginCredentials = {
  username: string;
  password: string;
  rememberMe: boolean;
};

export type Jwt = {
  id_token: string;
};
