import { jwtDecode } from 'jwt-decode';

export const DEBOUCE_TIME = 800;
export const LOCAL_CHANGE = 'localChange';

export function isNotNull(value: any) {
  return value !== null && value !== undefined;
}

export function isNull(value: any) {
  return value === null || value === undefined;
}

/**
 * Checks if a JWT is expired.
 * @param token - The JWT token as a string.
 * @returns boolean - Returns true if the token is expired, otherwise false.
 */
export function isJwtExpired(token: string): boolean {
  try {
    const decoded = jwtDecode(token);
    if (!decoded.exp) {
      // Token does not have an expiration time, consider it valid
      return false;
    }

    const currentTime = Date.now() / 1000; // Current time in seconds since the epoch
    return decoded.exp < currentTime;
  } catch (error) {
    console.error('Invalid JWT Token:', error);
    // If there was an error decoding the token, consider it expired.
    return true;
  }
}
