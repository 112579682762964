import { Injectable } from '@angular/core';
import { ManagerInterface } from './manager.interface';
import { Manager } from '../../models/users/manager.model';
import { FiltersModel } from '../../models/paging/filters.model';
import { DefaultPageable, PageableModel } from '../../models/paging/pageable-model';
import { Page } from '../../models/paging/page';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class ManagerService implements ManagerInterface {
  constructor(private httpClient: HttpClient) {}

  /**
   * Get a manager from a user id
   *
   * @param userId
   * @returns
   */
  getManager(userId: number): Observable<Manager> {
    return this.httpClient.get<Manager>(environment.apiUrl + '/managers/user/' + userId);
  }

  /**
   * Get all managers
   *
   * @returns
   */
  getAllManagers(filters?: FiltersModel, pageable?: PageableModel): Observable<Page<Manager>> {
    const paramsPage = pageable ? pageable.toParams() : DefaultPageable.getPageable().toParams();
    const filtersPage = filters ? filters.toParamsWithProperty() : new FiltersModel().toParamsWithProperty();

    const params = [paramsPage, filtersPage].filter((p) => p != null).join('&');
    return this.httpClient.get<Page<Manager>>(environment.apiUrl + '/managers?' + params);
  }

  updateManager(manager: Manager): Observable<Manager> {
    return this.httpClient.put<Manager>(environment.apiUrl + '/managers/user/' + manager.userId, manager);
  }
}
