import { Injectable } from '@angular/core';
import { Observable, Subject, catchError, firstValueFrom, switchMap } from 'rxjs';
import { User } from 'src/app/models/users/user.model';
import { AccountService } from '../account/account.service';
import { AthleteService } from '../athlete/athlete.service';
import { AuthorityVerificationService } from '../authority-verification/authority-verification.service';
import { ManagerService } from '../manager/manager.service';

@Injectable({
  providedIn: 'root',
})
export class PrincipalService {
  constructor(
    private accountService: AccountService,
    private managerService: ManagerService,
    private athleteService: AthleteService,
    private authorityVerificationService: AuthorityVerificationService,
  ) {}

  async identity(): Promise<User> {
    const account = await firstValueFrom(this.accountService.get());
    if (account.id) {
      const athlete = await firstValueFrom(this.athleteService.getAthlete(account.id));
      account.athlete = athlete;
      if (
        this.authorityVerificationService.hasTestingManagerRole(account) ||
        this.authorityVerificationService.hasS2ALocalRole(account)
      ) {
        const manager = await firstValueFrom(this.managerService.getManager(account.id));
        account.manager = manager;
      }
    }
    return account;
  }
}
