import { Injectable } from '@angular/core';
import { CriteriaSetInterface } from './criteria-set.interface';
import { FiltersModel } from '../../models/paging/filters.model';
import { DefaultPageable, PageableModel } from '../../models/paging/pageable-model';
import { Page } from '../../models/paging/page';
import { CriteriaSet } from '../../models/surveys/criteria-set.model';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Observable, switchMap } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class CriteriaSetService implements CriteriaSetInterface {
  constructor(private httpClient: HttpClient) {}

  /**
   * Get a all criterias set
   *
   * @returns
   */
  getAllCriteriaSets(filters?: FiltersModel, pageable?: PageableModel): Observable<Page<CriteriaSet>> {
    const paramsPage = pageable ? pageable.toParams() : DefaultPageable.getPageable().toParams();
    let generalParamFilters;
    let idsNotIncludeParamFilters = null;
    let langFieldParamFilters = null;

    if (filters != null) {
      const generalFilters = filters.getModelExcludeProperties(['idsNotInclude', 'titleField', 'descriptionField']);
      generalParamFilters = generalFilters.toParamsWithProperty();

      const idsNotIncludeFilters = filters.getModelIncludeProperties(['idsNotInclude']);
      idsNotIncludeParamFilters = idsNotIncludeFilters.toParams('idsNotInclude');

      const langFieldFilters = filters.getModelIncludeProperties(['titleField', 'descriptionField']);
      langFieldParamFilters = langFieldFilters.toParamsLangField('langFieldsFilters');
    }

    const params = [paramsPage, generalParamFilters, idsNotIncludeParamFilters, langFieldParamFilters]
      .filter((p) => p != null)
      .join('&');
    return this.httpClient.get<Page<CriteriaSet>>(environment.apiUrl + '/criteria-sets?' + params);
  }

  /**
   * Get a criteria set from an id
   *
   * @returns
   */
  getCriteriaSet(id: number): Observable<CriteriaSet> {
    return this.httpClient
      .get<CriteriaSet>(environment.apiUrl + '/criteria-sets/' + id)
      .pipe(switchMap(async (criteriaSet) => Object.assign(new CriteriaSet(), criteriaSet)));
  }

  /**
   * Add a criteria set
   *
   * @param criteriaSet
   * @returns
   */
  addCriteriaSet(criteriaSet: CriteriaSet): Observable<CriteriaSet> {
    return this.httpClient
      .post<CriteriaSet>(environment.apiUrl + '/criteria-sets', criteriaSet)
      .pipe(switchMap(async (criteriaSet) => Object.assign(new CriteriaSet(), criteriaSet)));
  }

  /**
   * Update a criteria set
   *
   * @param criteriaSet
   * @returns
   */
  updateCriteriaSet(criteriaSet: CriteriaSet): Observable<CriteriaSet> {
    return this.httpClient
      .put<CriteriaSet>(environment.apiUrl + '/criteria-sets', criteriaSet)
      .pipe(switchMap(async (criteriaSet) => Object.assign(new CriteriaSet(), criteriaSet)));
  }

  /**
   * Delete a criteria set
   *
   * @param criteriaSet
   * @returns
   */
  deleteCriteriaSet(criteriaSet: CriteriaSet): Observable<void> {
    return this.httpClient.delete<void>(environment.apiUrl + '/criteria-sets/' + criteriaSet.id);
  }
}
