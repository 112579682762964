import { HttpParams } from '@angular/common/http';
import { LanguageService } from 'src/app/services/language/language.service';
import { AdminGridColumnModel } from '../../pages/admin/AdminGrid';

export class FiltersModel {
  filters: FilterModel[];
  orFiltersGroup: Map<string, FilterModel[]> = new Map<string, FilterModel[]>();

  constructor() {
    this.filters = [];
    this.orFiltersGroup = new Map<string, FilterModel[]>();
  }

  public addFilter(filter: FilterModel) {
    this.filters.push(filter);
  }

  public deleteFilter(index: number) {
    if (index > -1 && index < this.filters.length) {
      this.filters.splice(index, 1);
    }
  }

  public setOrFiltersGroup(groupKey: string, searchValue: string, columns: AdminGridColumnModel[]) {
    if (columns != null && columns.length > 0) {
      this.orFiltersGroup.set(
        groupKey,
        columns
          .filter((column) => {
            if (column.type === 'number') {
              return isNaN(parseInt(searchValue)) ? false : true;
            } else if (column.type == 'boolean') {
              return searchValue === 'true' || searchValue == 'false';
            }
            return true;
          })
          .map((column) => {
            if (column.type === 'number') {
              return new FilterModel(
                column.filterKey,
                parseInt(searchValue),
                column.name,
                undefined,
                undefined,
                undefined,
                '',
                undefined,
              );
            } else if (column.type === 'boolean') {
              if (searchValue === 'true') {
                return new FilterModel(
                  column.filterKey,
                  true,
                  column.name,
                  undefined,
                  undefined,
                  undefined,
                  '',
                  undefined,
                );
              } else if (column.type == 'boolean') {
                return new FilterModel(
                  column.filterKey,
                  false,
                  column.name,
                  undefined,
                  undefined,
                  undefined,
                  '',
                  undefined,
                );
              }
            }
            return new FilterModel(
              column.filterKey,
              searchValue,
              column.name,
              undefined,
              undefined,
              undefined,
              '',
              undefined,
            );
          }),
      );
    }
  }

  public toParamsWithProperty(key: string = 'search'): HttpParams {
    let options: HttpParams = new HttpParams();

    if ((this.filters && this.filters.length > 0) || !this.mapIsEmpty(this.orFiltersGroup)) {
      const valueFilters = this.filters.map((filter) => filter.toParam(true)).join(';');

      let valuesFiltersOr = '';
      Array.from(this.orFiltersGroup.keys()).forEach((filterKey) => {
        const arr = this.orFiltersGroup.get(filterKey);
        if (arr) {
          let value = arr.map((filter) => filter.toParam(true)).join(',');
          if (value) {
            value = '(' + value + ')';
          }

          if (valuesFiltersOr && value) {
            valuesFiltersOr += ';';
          }

          valuesFiltersOr += value;
        }
      });

      options = options.set(
        key,
        valueFilters + (this.filters && this.filters.length > 0 && valuesFiltersOr ? ';' : '') + valuesFiltersOr,
      );
    }

    return options;
  }

  public toParams(key: string = 'search'): HttpParams {
    const filters = this.filters.concat(
      ...Array.from(this.orFiltersGroup.values()).reduce((acc, val) => acc.concat(val), []),
    );

    let options: HttpParams = new HttpParams();
    if (filters != null && filters.length > 0) {
      options = options.set(key, filters.map((filter) => filter.toParam()).join(','));
    }
    return options;
  }

  public toParamsLangField(key: string): HttpParams {
    const filters = this.filters.concat(
      ...Array.from(this.orFiltersGroup.values()).reduce((acc, val) => acc.concat(val), []),
    );

    let options: HttpParams = new HttpParams();
    if (filters != null && filters.length > 0) {
      options = options.set(key, filters.map((filter) => filter.toParamLangField()).join(';'));
    }
    return options;
  }

  public getModelIncludeProperties(includeProperties?: string[]): FiltersModel {
    const result = new FiltersModel();
    const filters = [];
    const orFiltersGroup = new Map<string, FilterModel[]>();

    if (this.filters != null && includeProperties != null) {
      filters.push(
        ...includeProperties
          .map((property) => this.filters.filter((f) => f.property == property))
          .reduce((acc, val) => acc.concat(val), [])
          .filter((r) => r != null),
      );
    }

    if (this.orFiltersGroup != null && includeProperties != null) {
      orFiltersGroup.set(
        'a',
        includeProperties
          .map((property) =>
            Array.from(this.orFiltersGroup.values())
              .reduce((acc, val) => acc.concat(val), [])
              .filter((f) => f.property == property),
          )
          .reduce((acc, val) => acc.concat(val), [])
          .filter((r) => r != null),
      );
    }

    result.filters = filters;
    result.orFiltersGroup = orFiltersGroup;

    return result;
  }

  public getModelExcludeProperties(excludeProperties?: string[]): FiltersModel {
    const result = new FiltersModel();
    let filters = this.filters;
    const orFiltersGroup = this.orFiltersGroup;

    if (this.filters != null && excludeProperties != null) {
      filters = this.filters.filter((f) => !excludeProperties.some((property) => property == f.property));
    }

    if (this.orFiltersGroup != null && excludeProperties != null) {
      Array.from(orFiltersGroup.keys()).forEach((filterKey) => {
        const arr = orFiltersGroup.get(filterKey);
        if (arr) {
          orFiltersGroup.set(
            filterKey,
            arr.filter((f) => f != null && !excludeProperties.some((property) => property == f.property)),
          );
        }
      });
    }

    result.filters = filters;
    result.orFiltersGroup = orFiltersGroup;

    return result;
  }

  public toLocalFilters(): any {
    let result = {};
    this.filters.forEach((filter) => (result = Object.assign({}, result, filter.toParamLocal())));
    return result;
  }

  private mapIsEmpty(map: Map<any, any[]>) {
    if (map == null || map.size == 0) {
      return true;
    }

    let isEmpty = true;

    const map_keys = Array.from(map.keys());
    map_keys.forEach((key) => {
      const k = map.get(key);
      if (k) {
        isEmpty = isEmpty && (k == null || k.length == 0);
      }
    });

    return isEmpty;
  }
}

export class FilterModel {
  public name: string | null;
  public property?: string;
  public searchValue: any;
  public exact: boolean;
  public translate: boolean;
  public notEqual: boolean;

  public localProperty: string | null;
  public isLocalFilter: boolean;

  constructor(
    property: string | undefined,
    value: any,
    name: string | null = null,
    exact: boolean = false,
    translate: boolean = false,
    notEqual: boolean = false,
    localProperty: string | null = null,
    isLocalFilter: boolean = true,
  ) {
    this.name = name;
    this.property = property;
    this.searchValue = value;
    this.exact = exact;
    this.translate = translate;
    this.notEqual = notEqual;
    this.localProperty = localProperty;
    this.isLocalFilter = isLocalFilter;
  }

  toParam(withKeyProperty: boolean = false) {
    if (!withKeyProperty) {
      return this.searchValue;
    } else {
      return this.property + (this.notEqual ? '!=' : '==') + this.transformValue();
    }
  }

  toParamLangField() {
    return this.property + ',' + LanguageService.currentLang + (this.notEqual ? '!=' : '==') + this.transformValue();
  }

  toParamLocal() {
    let res: Object | null = null;
    if (this.isLocalFilter) {
      const property = this.localProperty ? this.localProperty : this.property;
      if (property) {
        res = {
          [property]: {
            [this.notEqual ? '$ne' : '$eq']: this.searchValue,
          },
        };
      }
    }
    if (res) return res;
    else return {};
  }

  private transformValue() {
    if (typeof this.searchValue === 'string' && !this.exact) {
      return '*' + this.searchValue + '*';
    }

    return this.searchValue;
  }
}

export class DuoAndFilterModel extends FilterModel {
  andFilter: FilterModel;
  public name2: string;
  public property2: string;
  public value2: string;
  public exact2: boolean;

  constructor(
    name1: string | null,
    property1: string,
    value1: any,
    name2: string | null,
    property2: string,
    value2: any,
    exact1: boolean = false,
    exact2: boolean = false,
  ) {
    super(property1, value1, name1, exact1);
    this.andFilter = new FilterModel(property2, value2, name2, exact2);
  }

  override toParam(withKeyProperty: boolean = false) {
    return '(' + super.toParam(withKeyProperty) + ';' + this.andFilter.toParam(withKeyProperty) + ')';
  }
}

export class InFilterModel extends FilterModel {
  values: any[];
  notIn: boolean;

  constructor(property: string, values: any[], notIn: boolean = false, name: string | null = null) {
    super(property, null, name);
    this.values = values;
    this.notIn = notIn;
  }

  override toParam() {
    if (this.values && this.values.length > 0) {
      return this.property + '=' + (this.notIn ? 'out' : 'in') + '=(' + this.values.join(',') + ')';
    }

    return '';
  }
}

export class LessThanFilterModel extends FilterModel {
  andEqual: boolean;

  constructor(property: string, value: any, andEqual: boolean = true, name: string | null = null) {
    super(property, value, name);
    this.andEqual = andEqual;
  }

  override toParam() {
    return this.property + '=l' + (this.andEqual ? 'e' : 't') + '=' + this.searchValue;
  }
}

export class GreaterThanFilterModel extends FilterModel {
  andEqual: boolean;

  constructor(property: string, value: any, andEqual: boolean = true, name: string | null = null) {
    super(property, value, name);
    this.andEqual = andEqual;
  }

  override toParam() {
    return this.property + '=g' + (this.andEqual ? 'e' : 't') + '=' + this.searchValue;
  }
}
