import { LanguageService } from 'src/app/services/language/language.service';
import { isNotNull } from 'src/app/utils/general.utils';
import { TwoListsSelectModel } from '../../components/two-lists-select/two-lists-select.model';
import { LangFieldUtils } from '../../utils/lang-field.utils';
import { Category } from '../events/category.model';
import { LangField } from '../lang-field.model';
import { CriteriaValue } from './criteria-value.model';
import { SurveyCriteriaSetResponse } from './survey-criteriaset-response.model';

export class Criteria extends TwoListsSelectModel {
  public titleField: LangField = { id: null, langValues: [] };
  public descriptionField: LangField = { id: null, langValues: [] };
  public ratingType: RatingType;
  public custom: boolean;
  public categoryId: number;
  public categoryName: string;
  public descLeft: LangField = { id: null, langValues: [] };
  public descMidLeft: LangField = { id: null, langValues: [] };
  public descMiddle: LangField = { id: null, langValues: [] };

  public descMidRight: LangField = { id: null, langValues: [] };
  public descRight: LangField = { id: null, langValues: [] };
  public values: CriteriaValue[] = [];
  public response: number;
  private _category: Category;

  override get title(): string {
    return LangFieldUtils.getValueByLang(LanguageService.currentLang, this.titleField);
  }

  get category(): Category {
    return this._category;
  }

  set category(category: Category) {
    this._category = category;
    if (category && category.id) {
      this.categoryName = category.title;
      this.categoryId = category.id;
    } else console.error('null value category || category.id not set');
  }

  getDefaultValueFromRatingType() {
    switch (this.ratingType) {
      case RatingType.VAS_SCALE:
        return 5;
      case RatingType.JAR_SCALE_2:
        return 0;
      case RatingType.JAR_SCALE:
        return 0;
      case RatingType.SCALE:
        return 5;
      case RatingType.STAR_SCALE:
        return 5;
      case RatingType.RANGE_SCALE:
        return 2;
      case RatingType.LIKERT:
        return 1;
      default:
        return 0;
    }
  }

  getRatingMinAndMax() {
    switch (this.ratingType) {
      case RatingType.VAS_SCALE:
        return { min: 0, max: 10 };
      case RatingType.JAR_SCALE_2:
        return { min: -2, max: 2 };
      case RatingType.JAR_SCALE:
        return { min: -5, max: 5 };
      case RatingType.SCALE:
        return { min: 0, max: 10 };
      case RatingType.STAR_SCALE:
        return { min: 1, max: 10 };
      case RatingType.RANGE_SCALE:
        return { min: 2, max: 10 };
      case RatingType.LIKERT:
        return { min: 1, max: 8 };
      default:
        return { min: 0, max: 0 };
    }
  }

  getRatingMax() {
    switch (this.ratingType) {
      case RatingType.VAS_SCALE:
        return 10;
      case RatingType.JAR_SCALE_2:
        return 2;
      case RatingType.JAR_SCALE:
        return 5;
      case RatingType.SCALE:
        return 10;
      case RatingType.STAR_SCALE:
        return 5;
      case RatingType.RANGE_SCALE:
        return 5;
      case RatingType.LIKERT:
        return 8;
      default:
        return 0;
    }
  }

  findCriteriaValuesFromCriteriaValueIds(criteriaValueIds: any): string[] {
    if (this.values != null && criteriaValueIds != null) {
      const valuesReturns: string[] = [];
      criteriaValueIds.forEach((criteriaValueId: number[]) => {
        const indexes = this.values.map((value) => {
          if (value.id) return criteriaValueId.indexOf(value.id);
          else throw new Error('null value value id not found');
        });
        indexes.forEach((result, index) => {
          if (result > -1 && this.values[index].value != null) {
            valuesReturns.push(LangFieldUtils.getValueByLang(LanguageService.currentLang, this.values[index].value));
          }
        });
      });
      return valuesReturns.length > 0 ? valuesReturns : [];
    }
    return [];
  }

  convertValueDisplay(responses: SurveyCriteriaSetResponse[]): any {
    if (responses == null || responses.length == 0) {
      return '';
    } else if (this.ratingType != RatingType.MCQ && responses[0] == null) {
      return '';
    } else if (this.ratingType != RatingType.MCQ) {
      const response = responses[0];

      if (isNotNull(response.value) && isNotNull(response.criteriaValueId)) return null;

      if (this.ratingType == RatingType.RANGE_SCALE || this.ratingType == RatingType.STAR_SCALE) {
        if (response.value) return response.value / 2;
      } else if (this.ratingType == RatingType.LOV) {
        return [response.criteriaValueId];
      } else {
        return response.value;
      }
    } else {
      return responses.map((response) => response.criteriaValueId);
    }
  }

  isNumericRatingType(): boolean {
    return this.ratingType != RatingType.LOV && this.ratingType != RatingType.MCQ;
  }

  isVasOrJarScale(): boolean {
    return (
      this.ratingType == RatingType.JAR_SCALE ||
      this.ratingType == RatingType.VAS_SCALE ||
      this.ratingType == RatingType.JAR_SCALE_2
    );
  }
}

export enum RatingType {
  VAS_SCALE = 'VAS_SCALE',
  JAR_SCALE_2 = 'JAR_SCALE_2',
  JAR_SCALE = 'JAR_SCALE',
  SCALE = 'SCALE',
  STAR_SCALE = 'STAR_SCALE',
  RANGE_SCALE = 'RANGE_SCALE',
  LOV = 'LOV',
  LIKERT = 'LIKERT',
  MCQ = 'MCQ',
}

export type SingleSelectedValue = {
  criteria: Criteria;
  selectedValue: number;
};

export type MultipleSelectedValue = {
  criteria: Criteria;
  selectedValues: (number | null)[];
};
