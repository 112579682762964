import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { SurveySummary } from '../../models/surveys/survey-summary.model';
import { Survey, UserSurveyStatus } from '../../models/surveys/survey.model';
import { SurveyInterface } from './survey.interface';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class SurveyService implements SurveyInterface {
  constructor(private httpClient: HttpClient) {}

  /**
   * Get a survey from an id
   *
   * @returns
   */
  getSurveysResponseForCurrentUser(idEvent: number): Observable<Survey[]> {
    return this.httpClient.get<Survey[]>(environment.apiUrl + `/surveys/${idEvent}`);
  }

  /**
   * Get a survey from an id
   *
   * @returns
   */
  getSurveysResponseForUser(idEvent: number, idUser: number): Observable<Survey[]> {
    return this.httpClient.get<Survey[]>(environment.apiUrl + `/surveys/${idEvent}/${idUser}`);
  }

  getAllSurveys(): Observable<SurveySummary[]> {
    return this.httpClient.get<SurveySummary[]>(environment.apiUrl + '/surveys/');
  }

  updateSurvey(survey: Survey): Observable<Survey> {
    return this.httpClient.put<Survey>(environment.apiUrl + '/surveys', survey);
  }

  validateSurvey(survey: Survey): Observable<Survey> {
    survey.surveyStatus = UserSurveyStatus.COMPLETED;
    return this.updateSurvey(survey);
  }

  sendSurvey(survey: Survey): Observable<Survey> {
    return this.httpClient.post<Survey>(environment.apiUrl + `/surveys/${survey.id}/FINALISED`, null);
  }

  /**
   *
   * @param eventId
   * @param surveys only used in composite for mobile sync at send survey
   * @return
   */
  validateAllSurveys(eventId: number): Observable<void> {
    return this.httpClient.post<void>(environment.apiUrl + '/surveys/all/' + eventId + '/FINALISED', null);
  }

  getSurveyById(idSurvey: number): Observable<Survey> {
    return this.httpClient.get<Survey>(environment.apiUrl + `/survey/${idSurvey}`);
  }
}
