import { Criteria, RatingType } from './criteria.model';
import { SurveyCriteria } from './survey-criteria.interface';
import { LangField } from '../lang-field.model';
import { TwoListsSelectModel } from '../../components/two-lists-select/two-lists-select.model';
import { LangFieldUtils } from '../../utils/lang-field.utils';
import { LanguageService } from 'src/app/services/language/language.service';

export class CriteriaSetCriteria extends TwoListsSelectModel implements SurveyCriteria {
  public criteriaOrder: number;
  public criteriaId: number | null;
  public criteriaTitle: LangField = { id: null, langValues: [] };
  public criteriaDescription: LangField = { id: null, langValues: [] };
  public criteriaSetId: number | null;
  public criteriaSetTitle: LangField = { id: null, langValues: [] };
  public selected: boolean; // only frontend
  public disabled: boolean;
  private _criteria: Criteria;

  get criteria(): Criteria {
    if (this._criteria == null) {
      this._criteria = new Criteria();
    }

    this._criteria.titleField = this.criteriaTitle;
    this._criteria.descriptionField = this.criteriaDescription;
    this._criteria.id = this.criteriaId;
    return Object.assign(new Criteria(), this._criteria);
  }

  set criteria(criteria: Criteria) {
    this._criteria = Object.assign(new Criteria(), criteria);
    if (criteria) {
      this.criteriaId = criteria.id ?? null;
      this.criteriaTitle = criteria.titleField;
      this.criteriaDescription = criteria.descriptionField;
    }
  }

  override get title(): string {
    return LangFieldUtils.getValueByLang(LanguageService.currentLang, this.criteriaTitle);
  }

  override get subtitle(): string {
    return LangFieldUtils.getValueByLang(LanguageService.currentLang, this.criteriaSetTitle);
  }

  getRatingType(): RatingType {
    return this._criteria.ratingType;
  }
}
