import { Injectable } from '@angular/core';
import { EventUserRanking } from '../../models/events/ranking/event-user-ranking.model';
import { Ranking } from '../../models/events/ranking/ranking';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class RankingService {
  constructor(private httpClient: HttpClient) {}

  addRankings(ranking: Ranking[]): Observable<EventUserRanking> {
    return this.httpClient.post<EventUserRanking>(environment.apiUrl + '/rankings', ranking);
  }

  getUserRankings(userId: number, eventId: number): Observable<EventUserRanking> {
    return this.httpClient.get<EventUserRanking>(environment.apiUrl + `/rankings/${userId}/${eventId}`);
  }
}
