import { Injectable } from '@angular/core';
import { AthleteInterface } from './athlete.interface';
import { PageableModel } from '../../models/paging/pageable-model';
import { FiltersModel } from '../../models/paging/filters.model';
import { Page } from '../../models/paging/page';
import { Athlete } from '../../models/users/athlete.model';
import { DefaultPageable } from '../../models/paging/pageable-model';
import { Subsidiary } from '../../models/users/subsidiary.model';
import { environment } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class AthleteService implements AthleteInterface {
  constructor(private httpClient: HttpClient) {}

  /**
   * Get an athlete from a user id
   *
   * @param userId
   * @returns
   */
  getAthlete(userId: number): Observable<Athlete> {
    return this.httpClient.get<Athlete>(environment.apiUrl + '/athletes/user/' + userId);
  }

  getAllAthletes(filters?: FiltersModel, pageable?: PageableModel): Observable<Page<Athlete>> {
    const paramsPage = pageable ? pageable.toParams() : DefaultPageable.getPageable().toParams();

    let generalParamFilters;
    let sportIdParamFilters;
    let questionTypeResponsesIdsParamFilters = null;

    if (filters != null) {
      const generalFilters = filters.getModelExcludeProperties(['sportId', 'questionTypeSportResponsesValuesIds']);
      generalParamFilters = generalFilters.toParamsWithProperty();

      const sportIdFilters = filters.getModelIncludeProperties(['sportId']);
      sportIdParamFilters = sportIdFilters.toParams('sportId');

      const questionTypeResponsesIdsFilters = filters.getModelIncludeProperties([
        'questionTypeSportResponsesValuesIds',
      ]);
      questionTypeResponsesIdsParamFilters = questionTypeResponsesIdsFilters.toParams(
        'questionTypeSportResponsesValuesIds',
      );
    }
    const queryParam = [paramsPage, generalParamFilters, sportIdParamFilters, questionTypeResponsesIdsParamFilters]
      .filter((p) => p != null)
      .join('&');
    return this.httpClient.get<Page<Athlete>>(environment.apiUrl + '/athletes?' + queryParam);
  }

  getSubsidiaryFromAthlete(userId: number): Observable<Subsidiary> {
    return this.httpClient.get<Subsidiary>(environment.apiUrl + `/athletes/subsidiary/user/${userId}`);
  }

  getAthleteForS2A(userId: number): Observable<Athlete> {
    return this.httpClient.get<Athlete>(environment.apiUrl + `/athletes/user/s2a/${userId}`);
  }

  save(athlete: Athlete): Observable<Athlete> {
    return this.httpClient.put<Athlete>(environment.apiUrl + `/athletes/user/${athlete.userId}`, athlete);
  }
}
