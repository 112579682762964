import { Injectable } from '@angular/core';
import { firstValueFrom } from 'rxjs';
import { Event, EventType } from 'src/app/models/events/event.model';
import { SurveyCriteriaSet } from 'src/app/models/surveys/survey-criteriaset.model';
import { isNull } from 'src/app/utils/general.utils';
import { Product } from '../../models/products/product.model';
import { CriteriaSet } from '../../models/surveys/criteria-set.model';
import { Criteria, RatingType } from '../../models/surveys/criteria.model';
import { CriteriaSetCriteria } from '../../models/surveys/criteriaset-criteria.model';
import { Survey, UserSurveyStatus } from '../../models/surveys/survey.model';
import { AuthService } from '../auth/auth.service';
import { CriteriaSetService } from '../criteria-set/criteria-set.service';
import { CriteriaService } from '../criteria/criteria.service';
import { EventStatusService } from '../event/event-status.service';
import { ProductService } from '../product/product.service';
import { SurveyService } from './survey.service';

export type EventSurveys = {
  id?: number;
  eventId?: number;
  surveys: Survey[];
  surveysGrouping: Map<string, Survey[]>;
};

@Injectable({
  providedIn: 'root',
})
export class SurveyLoadService {
  private productsLoaded: Product[] = [];
  private criteriaSetsLoaded: CriteriaSet[] = [];
  private criteriasLoaded: Criteria[] = [];

  constructor(
    private productService: ProductService,
    private criteriaSetService: CriteriaSetService,
    private criteriaService: CriteriaService,
    private surveyService: SurveyService,
    private eventStatusService: EventStatusService,
  ) {}

  async initSurveysFromEvent(event: Event, userId: number | undefined = undefined): Promise<EventSurveys> {
    const eventSurveys: EventSurveys = {
      surveys: [],
      surveysGrouping: new Map(),
    };

    if (!this.eventStatusService.isEventStatusBeforeSurveys(event.status) && event.id) {
      if (userId) {
        eventSurveys.surveys = await firstValueFrom(this.surveyService.getSurveysResponseForUser(event.id, userId));
      } else {
        eventSurveys.surveys = await firstValueFrom(this.surveyService.getSurveysResponseForCurrentUser(event.id));
      }
      if (!eventSurveys.surveys || eventSurveys.surveys.length == 0) {
        return {
          eventId: event.id,
          surveys: [],
          surveysGrouping: new Map(),
        };
      }
      eventSurveys.eventId = event.id;
      if (userId) {
        eventSurveys.surveys = eventSurveys.surveys.filter((survey) => survey.userId === userId);
      } else {
        const account = AuthService.currentUser!;
        eventSurveys.surveys = eventSurveys.surveys.filter((survey) => survey.userId === account.id);
      }

      const tmp_surveys = [];
      for (const survey of eventSurveys.surveys) {
        // Chargement de l'événement sur le survey
        survey.event = event;
        const surveyResult = await this.getSurveyWithAllRelationships(survey);
        tmp_surveys.push(surveyResult);
      }
      eventSurveys.surveys = [...tmp_surveys];

      eventSurveys.surveys = eventSurveys.surveys.sort((surveyA, surveyB) => surveyA.order - surveyB.order);

      if (event.type == EventType.DURABILITY_TEST) {
        for (const survey of eventSurveys.surveys) {
          let surveyToAdd = [survey];
          const key = survey.product.id + '-' + survey.stage + '-' + survey.version;

          if (Array.from(eventSurveys.surveysGrouping.keys()).indexOf(key) > -1) {
            let surveys = eventSurveys.surveysGrouping.get(key);
            if (surveys) {
              surveyToAdd = surveyToAdd.concat(surveys).sort((surveyA, surveyB) => {
                if (surveyA.period < surveyB.period) {
                  return -1;
                } else if (surveyB.period < surveyA.period) {
                  return 1;
                } else {
                  return 0;
                }
              });
            }
          }
          eventSurveys.surveysGrouping.set(key, surveyToAdd);
        }

        eventSurveys.surveysGrouping.forEach((surveys) => {
          if (surveys != null) {
            surveys.forEach((survey) => {
              survey.isDurabilitySurveyDisabled = this.isDurabilitySurveyDisabled(survey, eventSurveys.surveysGrouping);
            });
          }
        });
      }
    }

    return eventSurveys;
  }

  async getSurveyByIdWithAllRelationships(surveyId: number): Promise<Survey> {
    const s = await firstValueFrom(this.surveyService.getSurveyById(surveyId));
    return await this.getSurveyWithAllRelationships(s);
  }

  getCriteriasMandatoryNotAnswered(survey: Survey) {
    const criteriasToAnswer: CriteriaSetCriteria[] = [];

    for (const surveyCriteriaSet of survey.surveyCriteriaSets) {
      for (const criteriaCriteriaSet of surveyCriteriaSet.criteriaSet.criterias) {
        if (criteriaCriteriaSet.getRatingType() === RatingType.MCQ) {
          // Validate all responses for mcq
          const responses = surveyCriteriaSet.responses.filter(
            (response) => response.criteriaId == criteriaCriteriaSet.criteriaId,
          );
          const noResponse = responses.every((response) => isNull(response.criteriaValueId));
          if (noResponse) {
            criteriasToAnswer.push(criteriaCriteriaSet);
          }
        } else {
          // Validate only one response for the rest
          const response = surveyCriteriaSet.responses.find(
            (response) => response.criteriaId == criteriaCriteriaSet.criteriaId,
          );
          if (isNull(response) || (isNull(response?.criteriaValueId) && isNull(response?.value))) {
            criteriasToAnswer.push(criteriaCriteriaSet);
          }
        }
      }
    }

    return criteriasToAnswer;
  }

  private async getSurveyWithAllRelationships(survey: Survey): Promise<Survey> {
    survey.surveyCriteriaSets = survey.surveyCriteriaSets.sort((surveyCriteriaSetA, surveyCriteriaSetB) => {
      if (surveyCriteriaSetA.criteriaSetOrder < surveyCriteriaSetB.criteriaSetOrder) {
        return -1;
      } else if (surveyCriteriaSetA.criteriaSetOrder > surveyCriteriaSetB.criteriaSetOrder) {
        return 1;
      } else {
        return 0;
      }
    });

    // Get product linked to survey
    let product = this.productsLoaded.find((product) => product.id == survey.productId);
    if (product) {
      survey.product = product;
    } else {
      const product = await firstValueFrom(this.productService.getProduct(survey.productId));
      survey.product = Object.assign(new Product(), product);
      this.productsLoaded.push(survey.product);
    }

    survey.surveyCriteriaSets = survey.surveyCriteriaSets.map((surveyCriteriaSet) =>
      Object.assign(new SurveyCriteriaSet(), surveyCriteriaSet),
    );

    // Get criteria sets of each item
    for (const surveyCriteriaSet of survey.surveyCriteriaSets) {
      let criteriaSet = this.criteriaSetsLoaded.find(
        (criteriaSet) => criteriaSet.id == surveyCriteriaSet.criteriaSetId,
      );
      if (criteriaSet) {
        surveyCriteriaSet.criteriaSet = criteriaSet;
      } else {
        criteriaSet = await firstValueFrom(this.criteriaSetService.getCriteriaSet(surveyCriteriaSet.criteriaSetId));
        surveyCriteriaSet.criteriaSet = Object.assign(new CriteriaSet(), criteriaSet);
        this.criteriaSetsLoaded.push(surveyCriteriaSet.criteriaSet);
      }
    }

    if (survey.surveyCriteriaSets != null && survey.surveyCriteriaSets.length > 0) {
      for (const surveyCriteriaSet of survey.surveyCriteriaSets) {
        const criterias: Criteria[] = [];
        const currentCriteriaSetId = surveyCriteriaSet.criteriaSetId;

        for (const index in surveyCriteriaSet.criteriaSet.criterias) {
          let criteria = this.criteriasLoaded.find(
            (criteria) => criteria.id == surveyCriteriaSet.criteriaSet.criterias[index].criteriaId,
          );
          if (criteria) {
            criterias.push(criteria);
          } else {
            const criteriaId = surveyCriteriaSet.criteriaSet.criterias[index].criteriaId;
            if (criteriaId) {
              criteria = await firstValueFrom(this.criteriaService.getCriteria(criteriaId));
              criterias.push(criteria);
              this.criteriasLoaded.push(criteria);
            } else console.error('null value criteria id not set');
          }
        }

        // Update criterias in survey.surveyCriteriaSets
        criterias.forEach((criteria) => {
          const scs = survey.surveyCriteriaSets.find(
            (surveyCriteriaSet) => surveyCriteriaSet.criteriaSetId == currentCriteriaSetId,
          );
          if (scs) {
            const criteriaSet = scs.criteriaSet;
            const criteriaToUpdate = criteriaSet.criterias.find((csc) => csc.criteriaId == criteria.id);
            if (criteriaToUpdate) {
              criteriaToUpdate.criteria = Object.assign(new Criteria(), criteria);
            }
          }
        });

        // Update criteriaSetCriterias
        const scs = survey.surveyCriteriaSets.find(
          (surveyCriteriaSet) => surveyCriteriaSet.criteriaSetId == currentCriteriaSetId,
        );
        if (scs) {
          const criteriaSetCriterias = scs.criteriaSet.criterias;
          criteriaSetCriterias.forEach((criteriaSetCriteria, index) => {
            const criteriaSetCriteriaUpdated = Object.assign(new CriteriaSetCriteria(), criteriaSetCriteria);
            scs.criteriaSet.criterias[index] = criteriaSetCriteriaUpdated;
          });
        }
      }
    }

    return survey;
  }

  private isDurabilitySurveyDisabled(survey: Survey, surveysGrouping: Map<string, Survey[]>): boolean {
    let listSurveys = surveysGrouping.get(survey.product.id + '-' + survey.stage + '-' + survey.version);
    if (listSurveys) {
      listSurveys = listSurveys.filter(
        (surveyB) =>
          surveyB.period < survey.period &&
          (surveyB.surveyStatus == UserSurveyStatus.INIT || surveyB.surveyStatus == UserSurveyStatus.COMPLETED),
      );
    }
    if (listSurveys) return listSurveys.length > 0;
    return false;
  }
}
