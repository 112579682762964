import { TwoListsSelectModel } from '../../components/two-lists-select/two-lists-select.model';
import { Category } from '../events/category.model';
import { CriteriaSetCriteria } from './criteriaset-criteria.model';
import { Auditable } from '../auditable.model';
import { LangField } from '../lang-field.model';
import { UserExtended } from '../users/user-extended.model';
import { LangFieldUtils } from '../../utils/lang-field.utils';
import { LanguageService } from 'src/app/services/language/language.service';

export class CriteriaSet extends TwoListsSelectModel {
  public titleField: LangField = { id: null, langValues: [] };
  public descriptionField: LangField = { id: null, langValues: [] };
  public responsibleId: number;
  public responsibleFirstName: string;
  public responsibleLastName: string;
  public categoryId: number;
  public categoryName: string;
  public auditable: Auditable;
  public criterias: CriteriaSetCriteria[] = [];
  public custom: boolean;
  private _category: Category;
  private _responsible: UserExtended;

  override get title(): string {
    return LangFieldUtils.getValueByLang(LanguageService.currentLang, this.titleField);
  }

  get category(): Category {
    return this._category;
  }

  set category(category: Category) {
    this._category = category;
    if (category && category.id) {
      this.categoryName = category.title;
      this.categoryId = category.id;
    }
  }
  get responsible(): UserExtended {
    return this._responsible;
  }

  set responsible(responsible: UserExtended) {
    this._responsible = responsible;
    if (responsible.firstName && responsible.lastName && responsible.id) {
      this.responsibleFirstName = responsible.firstName;
      this.responsibleLastName = responsible.lastName;
      this.responsibleId = responsible.id;
    }
  }
}
