import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { LoadingController } from '@ionic/angular/standalone';
import { TranslateService } from '@ngx-translate/core';
import { firstValueFrom, interval, Subscription, timeout } from 'rxjs';
import { AuthService } from '../auth/auth.service';
import { Events } from '../events/events.service';
import { OfflineService } from '../offline/offline.service';
import { SyncService } from '../sync/sync.service';
import { NetworkSpeedService } from './network-speed.service';
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class NetworkService {
  public static isConnected: boolean;
  private pingSubscription: Subscription;
  private readonly pingInterval = 2000;
  private readonly pingTimeout = 2000;
  private isRunningSpeedTest: boolean = false;

  constructor(
    private http: HttpClient,
    private events: Events,
    private syncService: SyncService,
    private offlineService: OfflineService,
    private networkSpeedService: NetworkSpeedService,
    private loadingCtrl: LoadingController,
    private translateService: TranslateService,
  ) {}

  async launchNetworkListener() {
    await this.pingServer();
    this.pingSubscription = interval(this.pingInterval).subscribe(() => this.pingServer());
  }

  ngOnDestroy() {
    if (this.pingSubscription) {
      this.pingSubscription.unsubscribe();
    }
  }

  private async pingServer() {
    try {
      if (!this.isRunningSpeedTest) {
        const response = await firstValueFrom(
          this.http.get(environment.apiUrl + '/public/ping', { responseType: 'text' }).pipe(timeout(this.pingTimeout)),
        );
        const connected = response === 'Ok';
        if (connected !== NetworkService.isConnected) {
          NetworkService.isConnected = connected;
          this.events.publish('network-changed', { isConnected: NetworkService.isConnected });
          await this.handleNetworkChange();
        }
      }
    } catch (error) {
      console.error('Connection test failed', error);
      if (NetworkService.isConnected) {
        NetworkService.isConnected = false;
        this.events.publish('network-changed', { isConnected: NetworkService.isConnected });
      }
    }
  }

  private async handleNetworkChange() {
    const account = AuthService.currentUser;
    if (account && NetworkService.isConnected && !OfflineService.isOffline) {
      this.isRunningSpeedTest = true;
      const message = await firstValueFrom(this.translateService.get('TESTING_NETWORK_SPEED'));
      const loading = await this.loadingCtrl.create({
        message,
        animated: true,
        backdropDismiss: false,
      });
      await loading.present();
      const isSlow = await this.networkSpeedService.isNetworkSlow();
      if (isSlow) {
        await loading.dismiss();
        await this.networkSpeedService.showSlowNetworkAlert();
        await this.offlineService.setMode(true);
      } else {
        await loading.dismiss();
        await this.syncService.sync();
      }
      this.isRunningSpeedTest = false;
    }
  }
}
