import { Platform } from '@ionic/angular';
import { NetworkService } from '../services/network/network.service';
import { OfflineService } from '../services/offline/offline.service';

export const isMobileOffline = (platform: Platform) => {
  return platform.is('capacitor') && (OfflineService.isOffline || !NetworkService.isConnected);
};

export const isNetworkAvailable = (platform: Platform) => {
  return platform.is('capacitor') && NetworkService.isConnected;
};
