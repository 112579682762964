import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { User } from 'src/app/models/users/user.model';
import { environment } from 'src/environments/environment';
import { AccountServiceInterface } from './account-service.interface';

@Injectable({
  providedIn: 'root',
})
export class AccountService implements AccountServiceInterface {
  constructor(private httpClient: HttpClient) {}

  get(): Observable<User> {
    return this.httpClient.get<User>(environment.apiUrl + '/account');
  }

  save(account: User): Observable<boolean> {
    return this.httpClient.post<boolean>(environment.apiUrl + '/account', account);
  }

  saveCurrent(account: User): Observable<boolean> {
    return this.httpClient.post<boolean>(environment.apiUrl + '/account/currentUser', account);
  }
}
