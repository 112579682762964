import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { Language } from '../../models/language.model';
import { AuthService } from '../auth/auth.service';
import { LanguageInterface } from './language.interface';

export enum LanguageEnum {
  EN = 'en',
  FR = 'fr',
  ES = 'es',
  DE = 'de',
}

@Injectable({
  providedIn: 'root',
})
export class LanguageService implements LanguageInterface {
  public static defaultlang = LanguageEnum.EN;
  public static currentLang: LanguageEnum;

  constructor(
    private httpClient: HttpClient,
    private authService: AuthService,
    private translateService: TranslateService,
  ) {}

  getAllLanguages(): Observable<Language[]> {
    return this.httpClient.get<Language[]>(environment.apiUrl + '/language/all');
  }

  changeLanguage(language: LanguageEnum) {
    LanguageService.currentLang = language;
    localStorage.setItem('language', language.toString());
  }

  async setupLanguage(): Promise<void> {
    // First check if user exists
    const user = this.authService.currentUser;
    if (user) {
      const language = user.langKey as LanguageEnum;
      LanguageService.currentLang = language;
      this.changeLanguage(language);
    } else {
      // If not check if a language is already saved in storage
      let language = localStorage.getItem('language') as LanguageEnum;
      if (!language) {
        // If a current language does not exist in storage we load default : en
        language = LanguageService.defaultlang;
        LanguageService.currentLang = language;
        this.changeLanguage(language);
      } else {
        // if a language exists we load it in the service as the current lang
        LanguageService.currentLang = language;
      }
    }
    // We use the loaded language in the entire app
    this.translateService.use(LanguageService.currentLang);
  }
}
