import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AlertController } from '@ionic/angular/standalone';
import { TranslateService } from '@ngx-translate/core';
import { firstValueFrom, timeout } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class NetworkSpeedService {
  private readonly downloadTimeout = 15000;
  private readonly slowNetworkThresholdMbps = 3;
  constructor(
    private httpClient: HttpClient,
    private alertCtrl: AlertController,
    private translateService: TranslateService,
  ) {}

  async isNetworkSlow(): Promise<boolean> {
    const startTime = performance.now();
    try {
      // Download a 5M file in bmp format
      const response = await firstValueFrom(
        this.httpClient
          .get(`${environment.apiUrl}/public/speed-test`, {
            responseType: 'blob',
            observe: 'response',
            headers: { 'Cache-Control': 'no-cache' },
          })
          .pipe(timeout(this.downloadTimeout)),
      );
      if (!response.body) return true;
      const endTime = performance.now();
      const duration = (endTime - startTime) / 1000; // Time in seconds
      const fileSize = response.body.size; // File size in bytes
      // Convert bytes per second to megabits per second
      const speedMbps = (fileSize * 8) / (duration * 1024 * 1024);
      return speedMbps <= this.slowNetworkThresholdMbps;
    } catch (error) {
      console.error('Download failed', error);
      return true;
    }
  }

  async showSlowNetworkAlert() {
    const values = await firstValueFrom(
      this.translateService.get(['SLOW_NETWORK_DETECTED', 'SLOW_NETWORK_DETECTED_HEADER']),
    );
    const alert = await this.alertCtrl.create({
      header: values.SLOW_NETWORK_DETECTED_HEADER,
      message: values.SLOW_NETWORK_DETECTED,
      buttons: [
        {
          text: 'Ok',
          role: 'destructive',
        },
      ],
      backdropDismiss: false,
    });
    await alert.present();
  }
}
