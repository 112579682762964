import { Injectable } from '@angular/core';
import { EventStatus } from 'src/app/models/events/event.model';

@Injectable({
  providedIn: 'root',
})
export class EventStatusService {
  isEventStatusBeforeSurveys(status: EventStatus): boolean {
    return status == EventStatus.INIT || status == EventStatus.QR_CODE_PENDING;
  }
}
