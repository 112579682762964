import { Injectable } from '@angular/core';
import { EventComplementaryInfoResponses } from '../../models/events/event-complementary-info-response.model';
import { ComplementaryInfoResponseInterface } from './complementary-info-response.interface';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class ComplementaryInfoResponseService implements ComplementaryInfoResponseInterface {
  constructor(private httpClient: HttpClient) {}

  getComplementaryInfoResponses(eventId: number): Observable<EventComplementaryInfoResponses> {
    return this.httpClient.get<EventComplementaryInfoResponses>(
      environment.apiUrl + '/event/complementary-info-responses/' + eventId,
    );
  }

  updateComplementaryInfoResponse(
    eventComplementaryInfoResponses: EventComplementaryInfoResponses,
  ): Observable<EventComplementaryInfoResponses> {
    return this.httpClient.put<EventComplementaryInfoResponses>(
      environment.apiUrl + '/event/complementary-info-responses',
      eventComplementaryInfoResponses,
    );
  }
}
