import { LangField } from '../lang-field.model';
import { ProductFamily } from '../products/product-family.model';
import { RaceType } from '../sports/race-type.model';
import { SportStyle } from '../sports/sport-style.model';
import { Sport } from '../sports/sport.model';
import { SurveyTemplate } from '../surveys/survey-template.model';
import { Athlete } from '../users/athlete.model';
import { Manager } from '../users/manager.model';
import { Category } from './category.model';
import { EventMajorCriteria } from './event-major-criteria.model';
import { EventProduct } from './event-product.model';
import { GuestInformation } from './guest-information.model';
import { Guest } from './guest.model';

export class Event {
  public id: number | null;
  public name = '';
  public eventMessage: string | null;
  public type: EventType;
  public place = '';
  public beginDate: Date | null;
  public endDate: Date | null;
  public testerSport: string;
  public discipline: Sport | null;
  public distance = 10;
  public description = '';
  public creator: Manager;
  public guests: Guest[];
  public testers: Athlete[];
  public status: EventStatus = EventStatus.INIT;
  public categoryName = '';
  public categoryId: number;
  public categoryColor = '';
  public productFamilyId: number | null;
  public productFamilyName: string | null;
  public raceTypeId: number;
  public raceTypeName: string;
  public styleId: number;
  public styleName: string;
  public styleType: string;
  public eventProducts: EventProduct[] = [];
  public eventMajorCriterias: EventMajorCriteria[] = [];
  public surveyTemplateId: number | null;
  public surveyTemplateName: LangField = { id: null, langValues: [] };
  public surveyTemplateCustom = false;
  public initialSurveyTemplateId: number | null;
  public initialSurveyTemplateName: LangField = { id: null, langValues: [] };
  public initialSurveyTemplateCustom = false;
  public notificationDetail: string = '';
  public howManySurveys: number = 1;
  public guestInfo: GuestInformation; // current user guest information on this event
  public productRanking: boolean;

  private _category: Category;
  private _productFamily: ProductFamily | null;
  private _disciplineName: string | null;
  private _raceType: RaceType | null;
  private _surveyTemplate: SurveyTemplate | null;
  private _initialSurveyTemplate: SurveyTemplate | null;
  private _style: SportStyle | null;

  get category(): Category {
    return this._category;
  }

  set category(category: Category) {
    this._category = category;
    if (category != null && category.id) {
      this.categoryName = category.name;
      this.categoryId = category.id;
      this.categoryColor = category.color;
      this.productFamily = null;
      this.disciplineName = null;
    }
  }

  get productFamily(): ProductFamily | null {
    return this._productFamily;
  }

  set productFamily(productFamily: ProductFamily | null) {
    this._productFamily = productFamily;
    if (productFamily != null && productFamily.id) {
      this.productFamilyId = productFamily.id;
      this.productFamilyName = productFamily.name;
    }
  }

  get disciplineName(): string | null {
    return this._disciplineName;
  }

  set disciplineName(value: string | null) {
    let setted = false;
    if (value != this._disciplineName) {
      setted = true;
    }

    this._disciplineName = value;

    if (value != null && setted && this.category != null && this.category.sports != null) {
      const discipline = this.category.sports.find((sport) => sport.name == value);
      if (discipline) {
        this.discipline = discipline;
      } else {
        this._disciplineName = '';
      }
      this.raceType = null;
      this.style = null;
    }
  }

  get raceType(): RaceType | null {
    return this._raceType;
  }

  set raceType(raceType: RaceType | null) {
    this._raceType = raceType;
    if (raceType != null && raceType.id) {
      this.raceTypeId = raceType.id;
      this.raceTypeName = raceType.name;
    }
  }

  get surveyTemplate(): SurveyTemplate | null {
    return this._surveyTemplate;
  }

  set surveyTemplate(surveyTemplate: SurveyTemplate | null) {
    this._surveyTemplate = surveyTemplate;
    if (surveyTemplate && surveyTemplate.id) {
      this.surveyTemplateId = surveyTemplate.id;
      this.surveyTemplateName = surveyTemplate.titleField;
      this.surveyTemplateCustom = surveyTemplate.custom;
    }
  }

  get initialSurveyTemplate(): SurveyTemplate | null {
    return this._initialSurveyTemplate;
  }

  set initialSurveyTemplate(initialSurveyTemplate: SurveyTemplate | null) {
    this._initialSurveyTemplate = initialSurveyTemplate;
    if (initialSurveyTemplate && initialSurveyTemplate.id) {
      this.initialSurveyTemplateId = initialSurveyTemplate.id;
      this.initialSurveyTemplateName = initialSurveyTemplate.titleField;
      this.initialSurveyTemplateCustom = initialSurveyTemplate.custom;
    }
  }

  get style(): SportStyle | null {
    return this._style;
  }

  set style(style: SportStyle | null) {
    this._style = style;
    if (style != null && style.id) {
      this.styleId = style.id;
      this.styleName = style.name;
      this.styleType = style.type;
    }
  }
}

export enum EventStatus {
  INIT = 'INIT',
  QR_CODE_PENDING = 'QR_CODE_PENDING',
  SURVEYS_PENDING = 'SURVEYS_PENDING',
  SURVEYS_COMPLETED = 'SURVEYS_COMPLETED',
  SURVEYS_CLOSED = 'SURVEYS_CLOSED',
}

export enum EventType {
  PERFORMANCE_TEST = 'PERFORMANCE_TEST',
  DURABILITY_TEST = 'DURABILITY_TEST',
  RACE = 'RACE',
  STATIC_TEST = 'STATIC_TEST',
}
