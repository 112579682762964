import { Injectable } from '@angular/core';
import { CommentInterface } from './comment.interface';
import { FeedbackComment } from '../../models/feedback/feedback-comment.model';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export abstract class CommentService implements CommentInterface {
  constructor(protected httpClient: HttpClient) {}

  abstract addComment(ids: string[], feedbackComment: FeedbackComment): Observable<FeedbackComment>;

  abstract updateComment(ids: string[], feedbackComment: FeedbackComment): Observable<FeedbackComment>;

  abstract deleteComment(ids: string[], commentId: number): Observable<void>;

  getComment(commentId: number): Observable<FeedbackComment> {
    return this.httpClient.get<FeedbackComment>(environment.apiUrl + '/comments/' + commentId);
  }
}
