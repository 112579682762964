import { Injectable } from '@angular/core';
import { Storage } from '@ionic/storage-angular';

@Injectable({
  providedIn: 'root',
})
export class StorageService {
  private _storage: Storage;

  constructor(private storage: Storage) {
    this.init();
  }

  async init() {
    if (!this._storage) this._storage = await this.storage.create();
  }

  public async set<T>(key: string, value: T): Promise<void> {
    await this.init();
    await this._storage?.set(key, value);
  }

  public async get<T>(key: string): Promise<T> {
    await this.init();
    const item = await this._storage?.get(key);
    return item;
  }

  public async remove(key: string): Promise<void> {
    await this.init();
    await this._storage?.remove(key);
  }

  public async clear(): Promise<void> {
    await this.init();
    await this._storage?.clear();
  }

  public async all() {
    const items: DatabaseItem[] = [];
    await this.storage.forEach((value, key) => {
      items.push({ key, value });
    });
    return items;
  }
}

export type DatabaseItem = {
  key: string;
  value: any;
};
