import { Injectable } from '@angular/core';
import { CriteriaInterface } from './criteria.interface';
import { Criteria } from '../../models/surveys/criteria.model';
import { Page } from '../../models/paging/page';
import { DefaultPageable, PageableModel } from '../../models/paging/pageable-model';
import { FiltersModel } from '../../models/paging/filters.model';
import { HttpClient } from '@angular/common/http';
import { Observable, switchMap } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class CriteriaService implements CriteriaInterface {
  constructor(private httpClient: HttpClient) {}

  /**
   * Get a all criterias
   *
   * @returns
   */
  getAllCriterias(filters?: FiltersModel, pageable?: PageableModel): Observable<Page<Criteria>> {
    const paramsPage = pageable ? pageable.toParams() : DefaultPageable.getPageable().toParams();
    let generalParamFilters;
    let idsNotIncludeParamFilters = null;
    let langFieldParamFilters = null;

    if (filters != null) {
      const generalFilters = filters.getModelExcludeProperties([
        'idsNotInclude',
        'titleField',
        'descriptionField',
        'descRight',
        'descMiddle',
        'descLeft',
      ]);
      generalParamFilters = generalFilters.toParamsWithProperty();

      const idsNotIncludeFilters = filters.getModelIncludeProperties(['idsNotInclude']);
      idsNotIncludeParamFilters = idsNotIncludeFilters.toParams('idsNotInclude');

      const langFieldFilters = filters.getModelIncludeProperties([
        'titleField',
        'descriptionField',
        'descRight',
        'descMiddle',
        'descLeft',
      ]);
      langFieldParamFilters = langFieldFilters.toParamsLangField('langFieldsFilters');
    }

    const params = [paramsPage, generalParamFilters, idsNotIncludeParamFilters, langFieldParamFilters]
      .filter((p) => p != null)
      .join('&');

    return this.httpClient.get<Page<Criteria>>(environment.apiUrl + '/criteria?' + params);
  }

  /**
   * Get a criteria from an id
   *
   * @returns
   */
  getCriteria(id: number): Observable<Criteria> {
    return this.httpClient
      .get<Criteria>(environment.apiUrl + '/criteria/' + id)
      .pipe(switchMap(async (criteria) => Object.assign(new Criteria(), criteria)));
  }

  /**
   * Add a criteria
   *
   * @param criteria
   * @returns
   */
  addCriteria(criteria: Criteria): Observable<Criteria> {
    return this.httpClient
      .post<Criteria>(environment.apiUrl + '/criteria', criteria)
      .pipe(switchMap(async (criteria) => Object.assign(new Criteria(), criteria)));
  }

  /**
   * Update a criteria
   *
   * @param criteria
   * @returns
   */
  updateCriteria(criteria: Criteria): Observable<Criteria> {
    return this.httpClient
      .put<Criteria>(environment.apiUrl + '/criteria', criteria)
      .pipe(switchMap(async (criteria) => Object.assign(new Criteria(), criteria)));
  }

  /**
   * Delete a criteria
   *
   * @param criteria
   * @returns
   */
  deleteCriteria(criteria: Criteria): Observable<void> {
    return this.httpClient.delete<void>(environment.apiUrl + '/criteria/' + criteria.id);
  }
}
