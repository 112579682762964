import { GuestStatus } from './guest.model';
import { SurveyInformation } from '../surveys/survey-information.model';
import { Ranking } from './ranking/ranking';
import { ProductCode } from '../products/product-code.model';

export class GuestInformation {
  userId: number;
  athleteFirstName: string;
  athleteLastName: string;
  guestStatus: GuestStatus;
  inviteDate: Date;
  anonymousTester: boolean = false;
  products: ProductCode[];
  notificationDate: Date;
  type: GuestInformationType;
  selected: boolean = true;
  surveyInformations: SurveyInformation[];
  rankings: Ranking[] = [];
  localChange: boolean;

  public getInvitationstringFromState() {
    let value;
    switch (this.guestStatus) {
      case GuestStatus.NONE:
        value = 'SURVEY_NOT_SEND';
        break;
      case GuestStatus.AUTOMATICALLY_ACCEPTED:
        value = 'INVITATION_AUTOMATICALLY_ACCEPTED_SURVEYS_PENDING';
        break;
      case GuestStatus.PRODUCT_AFFECTATION_ERROR:
        value = 'PRODUCT_AFFECTATION_ERROR';
        break;
      case GuestStatus.PRODUCT_AFFECTED:
        value = 'PRODUCT_AFFECTED';
        break;
      case GuestStatus.VALIDATION_PRODUCT_PENDING:
        value = 'VALIDATION_PRODUCT_PENDING';
        break;
      case GuestStatus.SURVEYS_TO_FILL:
        value = 'SURVEYS_TO_FILL';
        break;
      case GuestStatus.SURVEYS_COMPLETED:
        value = 'SURVEYS_SEND';
        break;
    }
    return value;
  }

  productsTostring() {
    let result = '';
    if (this.products != null) {
      this.products.forEach((product: ProductCode) => {
        const stageVersion =
          (product.productStage != null ? product.productStage : '') +
          (product.productVersion != null ? product.productVersion : '');
        result +=
          product.productName +
          (stageVersion != '' ? ' ' + stageVersion : '') +
          (product.productCode != null && product.productCode != '' ? ' (' + product.productCode + ')' : '') +
          ', ';
      });
    }

    if (result != '') {
      result = result.substring(0, result.length - 2);
    }

    return result;
  }
}

export enum GuestInformationType {
  NONE = 'NONE',
  GUEST_INVITATION = 'GUEST_INVITATION',
  TESTER_SURVEY = 'TESTER_SURVEY',
}
