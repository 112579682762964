import { Injectable } from '@angular/core';
import { LoadingController, Platform } from '@ionic/angular/standalone';
import { firstValueFrom } from 'rxjs';
import { CriteriaSetCommentService } from '../comment/criteria-set-comment/criteria-set-comment.service';
import { SurveyCommentService } from '../comment/survey-comment/survey-comment.service';
import { DatabaseService } from '../database/database.service';
import { StorageService } from '../storage/storage.service';
import { SurveyService } from '../survey/survey.service';
import { TranslateService } from '@ngx-translate/core';
import { LOCAL_CHANGE } from 'src/app/utils/general.utils';
import { RankingService } from '../ranking/ranking.service';

@Injectable({
  providedIn: 'root',
})
export class SyncService {
  constructor(
    private platform: Platform,
    private storageService: StorageService,
    private databaseService: DatabaseService,
    private loadingCtrl: LoadingController,
    private surveyService: SurveyService,
    private surveyCommentService: SurveyCommentService,
    private criteriaSetCommentService: CriteriaSetCommentService,
    private translateService: TranslateService,
    private rankingService: RankingService,
  ) {}

  async sync() {
    if (this.platform.is('capacitor')) {
      const localChange = await this.storageService.get(LOCAL_CHANGE);
      if (localChange) {
        const message = await firstValueFrom(this.translateService.get('SYNC_IN_PROGRESS'));
        const loader = await this.loadingCtrl.create({
          backdropDismiss: false,
          message,
        });
        await loader.present();

        const eventSurveys = await this.databaseService.eventSurveys.toArray();
        for (const group of eventSurveys) {
          const surveys = group.surveys;
          for (const survey of surveys) {
            const surveyCommentIds = [survey.id.toString()];
            if (survey.localChange) {
              try {
                await firstValueFrom(this.surveyService.updateSurvey(survey));
              } catch (err) {
                console.error(`error syncing survey ${survey.id} => `, err);
              }
            }
            for (const comment of survey.comments) {
              try {
                if (comment.localDel)
                  await firstValueFrom(this.surveyCommentService.deleteComment(surveyCommentIds, comment.id));
                else if (comment.localGen)
                  await firstValueFrom(this.surveyCommentService.addComment(surveyCommentIds, comment));
                else if (comment.localChange)
                  await firstValueFrom(this.surveyCommentService.updateComment(surveyCommentIds, comment));
              } catch (err) {
                console.error(`error syncing comment ${comment.id} => `, err);
              }
            }
            for (const surveyCriteriaSet of survey.surveyCriteriaSets) {
              const surveyCriteriaSetIds = [survey.id.toString(), surveyCriteriaSet.criteriaSetId.toString()];
              for (const comment of surveyCriteriaSet.comments) {
                try {
                  if (comment.localDel)
                    await firstValueFrom(
                      this.criteriaSetCommentService.deleteComment(surveyCriteriaSetIds, comment.id),
                    );
                  else if (comment.localGen)
                    await firstValueFrom(this.criteriaSetCommentService.addComment(surveyCriteriaSetIds, comment));
                  else if (comment.localChange)
                    await firstValueFrom(this.criteriaSetCommentService.updateComment(surveyCriteriaSetIds, comment));
                } catch (err) {
                  console.error(`error syncing comment ${comment.id} => `, err);
                }
              }
            }
          }
        }
        const anonTesters = await this.databaseService.getAllAnonTesters();
        for (const anonTester of anonTesters) {
          await firstValueFrom(this.rankingService.addRankings(anonTester.rankings));
        }

        await this.storageService.set(LOCAL_CHANGE, false);

        await loader.dismiss();
      }
    }
  }
}
