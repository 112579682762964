import { Injectable } from '@angular/core';
import { CategoryInterface } from './category.interface';
import { DefaultPageable, PageableModel } from '../../models/paging/pageable-model';
import { Page } from '../../models/paging/page';
import { Category, CategoryStatus } from '../../models/events/category.model';
import { FilterModel, FiltersModel } from '../../models/paging/filters.model';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class CategoryService implements CategoryInterface {
  constructor(private httpClient: HttpClient) {}

  /**
   * Get a all categories
   *
   * @returns
   */
  getAllCategories(filters?: FiltersModel, pageable?: PageableModel): Observable<Page<Category>> {
    const paramsPage = pageable ? pageable.toParams() : DefaultPageable.getPageable().toParams();

    let generalParamFilters: HttpParams;
    let managerParamFilters: HttpParams;
    let params: string;

    if (filters != null) {
      const generalFilters = filters.getModelExcludeProperties(['managedByCurrentUser']);
      generalParamFilters = generalFilters.toParamsWithProperty();
      const managerFilters = filters.getModelIncludeProperties(['managedByCurrentUser']);
      managerParamFilters = managerFilters.toParams('managedByCurrentUser');
      params = [paramsPage, generalParamFilters, managerParamFilters].filter((p) => p != null).join('&');
    } else {
      params = [paramsPage].filter((p) => p != null).join('&');
    }

    return this.httpClient.get<Page<Category>>(environment.apiUrl + `/categories?${params}`);
  }

  /**
   * Get a category from an id
   *
   * @returns
   */
  getCategory(id: number): Observable<Category> {
    return this.httpClient.get<Category>(environment.apiUrl + `/categories/${id}`);
  }

  getAllCategoriesFilteredByCurrentUser(
    paramFilters?: FiltersModel,
    pageable?: PageableModel,
  ): Observable<Page<Category>> {
    const filters = new FiltersModel();
    filters.filters.push(new FilterModel('statusStr', CategoryStatus.ACTIVE, '', true, false, false, '', true));
    filters.filters.push(new FilterModel('managedByCurrentUser', true, '', true, false, false, '', true));

    if (paramFilters != null) {
      filters.filters.push(...paramFilters.filters);
      paramFilters.orFiltersGroup.forEach((value, key) => filters.orFiltersGroup.set(key, value));
    }

    return this.getAllCategories(filters, pageable);
  }

  addCategory(category: Category): Observable<Category> {
    return this.httpClient.post<Category>(environment.apiUrl + '/categories', category);
  }

  updateCategory(category: Category): Observable<Category> {
    return this.httpClient.put<Category>(environment.apiUrl + '/categories', category);
  }

  deleteCategory(category: Category): Observable<void> {
    return this.httpClient.delete<void>(environment.apiUrl + '/categories/' + category.id);
  }

  activateCategory(id: number): Observable<Category> {
    return this.httpClient.get<Category>(environment.apiUrl + '/categories/' + id + '/activate');
  }

  desactivateCategory(id: number): Observable<Category> {
    return this.httpClient.get<Category>(environment.apiUrl + '/categories/' + id + '/deactivate');
  }
}
